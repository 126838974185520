import React  from "react";
import { Helmet } from "react-helmet";
import BenefitsGrid from "../../components/BenefitsGrid";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./ecomm-solutions.module.css";

// images
import paymentGateway from "../../images/payment-gateway.jpg";
import virtualTerminal from "../../images/virtual-terminal.jpg"
import inAppPayments from "../../images/in-app-payment.jpg";

export default function EcommSolutions() {
  return (
    <Layout>
      <Helmet>
        <title>Online payments | MyEZSolutions</title>
      </Helmet>
      
      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Online payments</h1>
          <p>Our advanced eCommerce payment technology enables you to accept digital payments securely and easily.<br />
            Connect with our global payment network to sell your products or services virtually anywhere in the world.</p>
        </div>
        
        <div className={styles.whyOnlineParent}>
          <div className={styles.whyOnlineHead}>
            Accepting credit and debit card payments online can be challenging. That’s why you need a partner that supports you. At Nuvei, we provide eCommerce payment processing solutions to thousands of online merchants and have developed powerful solutions at highly competitive rates. We make it simple for eCommerce businesses to get an internet merchant account, increase revenue and accept all major forms of electronic payment, quickly and easily.
            <br/>
            <br/>
            Need help choosing the right payment solution for your online business? Contact us for a free, no hassle consultation.
          </div>
          <h4 className={styles.whyOnlineTagline}>INNOVATIVE AND AFFORDABLE PAYMENT TECHNOLOGY</h4>
          <div className={styles.onlineBenefits}>
            <div className={styles.whyOnlineBenefit}>
              <div className={styles.whyOnlineImage}><img src={paymentGateway}
                        alt="Global Payments Gateway"/></div>
              <div className={styles.whyOnlineDescription}>
                <h4>Global Payment Gateway
                </h4>
                <p>Reach shoppers on any device, at any time, from any location. Our gateway connects with a global
                  network of banks, so you can grow your eCommerce business faster. Best of all? Access a global
                  marketplace to supercharge your sales even more!
        
                  Sell globally with 100+ currencies, plus local and alternative payments
                  High performance, reliability and fast transaction processing
                  Integrate with popular shopping carts including Magento, OpenCart and Wufoo
                  Complete security and privacy features protecting you and your customers, including tokenization</p>
              </div>
            </div>
            <div className={styles.whyOnlineBenefit}>
              <div className={styles.whyOnlineImage}><img src={virtualTerminal}
                        alt="Virtual Terminal"/></div>
              <div className={styles.whyOnlineDescription}>
                <h4>Virtual Terminal
                </h4>
                <p>Collect credit and debit card payments on your computer or tablet. Transactions are secure and easy
                  using our virtual terminal. Plus, you can set up recurring or installment billing for memberships or
                  subscriptions.
        
                  Process payments, refunds or void transactions in real-time or in batches
                  Manually key in credit card information for accepting payments
                  Print receipts immediately or securely store card information for invoicing</p>
              </div>
            </div>
            <div className={styles.whyOnlineBenefit}>
              <div className={styles.whyOnlineImage}><img src={inAppPayments} alt="In app payments"/></div>
              <div className={styles.whyOnlineDescription}>
                <h4>In-App Payments
                </h4>
                <p>Nuvei’s digital wallet service enables merchants to seamlessly accept Apple Pay and Google Pay online, in-app or at the point of sale – making it faster, simpler and more secure for customers to buy.
  
                  Simple checkout experience reduces shopping cart abandonment
                  Frictionless and secure payments using Touch ID or a Google account
                  Tokenization encrypts customer details for stress-free transactions
                  Developer-friendly—our API makes it easy to integrate</p>
              </div>
            </div>
          </div>
        </div>

        <BenefitsGrid title={"BUT IT DOESN'T STOP THERE"} items={[
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>),
            title: "Global Acquiring",
            description: "Sell virtually anywhere by accepting every major card brand in 100+ currencies.",
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M13.78 15.3L19.78 21.3L21.89 19.14L15.89 13.14L13.78 15.3M17.5 10.1C17.11 10.1 16.69 10.05 16.36 9.91L4.97 21.25L2.86 19.14L10.27 11.74L8.5 9.96L7.78 10.66L6.33 9.25V12.11L5.63 12.81L2.11 9.25L2.81 8.55H5.62L4.22 7.14L7.78 3.58C8.95 2.41 10.83 2.41 12 3.58L9.89 5.74L11.3 7.14L10.59 7.85L12.38 9.63L14.2 7.75C14.06 7.42 14 7 14 6.63C14 4.66 15.56 3.11 17.5 3.11C18.09 3.11 18.61 3.25 19.08 3.53L16.41 6.2L17.91 7.7L20.58 5.03C20.86 5.5 21 6 21 6.63C21 8.55 19.45 10.1 17.5 10.1Z" /></svg>),
            title: "Easy Integration",
            description: "A simple API and docs ensure a seamless integration with your software setup."
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M21,17V8H7V17H21M21,3A2,2 0 0,1 23,5V17A2,2 0 0,1 21,19H7C5.89,19 5,18.1 5,17V5A2,2 0 0,1 7,3H8V1H10V3H18V1H20V3H21M17.53,11.06L13.09,15.5L10.41,12.82L11.47,11.76L13.09,13.38L16.47,10L17.53,11.06M3,21H17V23H3C1.89,23 1,22.1 1,21V9H3V21Z" /></svg>),
            title: "Subscription Billing",
            description: "Easily manage recurring payments, subscription billing and other payment plans.",
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" /></svg>),
            title: "ACH Payments",
            description: "Reduce trips to the bank! Save time and money with online check processing, online or by telephone.",
          },
          {
            icon: (<svg viewBox="0 0 24 24"><path fill="currentColor" d="M18,11H14.82C14.4,9.84 13.3,9 12,9C10.7,9 9.6,9.84 9.18,11H6C5.67,11 4,10.9 4,9V8C4,6.17 5.54,6 6,6H16.18C16.6,7.16 17.7,8 19,8A3,3 0 0,0 22,5A3,3 0 0,0 19,2C17.7,2 16.6,2.84 16.18,4H6C4.39,4 2,5.06 2,8V9C2,11.94 4.39,13 6,13H9.18C9.6,14.16 10.7,15 12,15C13.3,15 14.4,14.16 14.82,13H18C18.33,13 20,13.1 20,15V16C20,17.83 18.46,18 18,18H7.82C7.4,16.84 6.3,16 5,16A3,3 0 0,0 2,19A3,3 0 0,0 5,22C6.3,22 7.4,21.16 7.82,20H18C19.61,20 22,18.93 22,16V15C22,12.07 19.61,11 18,11M19,4A1,1 0 0,1 20,5A1,1 0 0,1 19,6A1,1 0 0,1 18,5A1,1 0 0,1 19,4M5,20A1,1 0 0,1 4,19A1,1 0 0,1 5,18A1,1 0 0,1 6,19A1,1 0 0,1 5,20Z" /></svg>),
            title: "Developer-Friendly",
            description: "Sample code & dev tools to integrate, test and customize your implementation.",
          }
        ]} />
      </div>
    </Layout>
  );
}